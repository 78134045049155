import Link from 'next/link';
import Image from 'next/image';
import { Separator } from '@radix-ui/react-separator';
import { useTranslation } from 'react-i18next';
import { LearnMore } from '@/components';
import StackAICarousel from '../StackAICarousel';
import { mainPartners } from './helpers';

const PartnershipsSection = ({ offsetY }: { offsetY: number }) => {
  const { t } = useTranslation();

  return (
    <div className="relative mt-0  lg:mt-10 flex flex-col justify-end items-end py-20">
      <div className="flex flex-row justify-end items-center mb-9 child:duration-500">
        <h2 className="text-[#FFFFFF] font-extrabold text-4xl lg:text-5xl mx-5">
          {t('HOME_PARTNERSHIPS_TITLE')}
        </h2>
        <Separator className="h-10 lg:h-14 w-[0.4rem] lg:w-2 xl:w-2 2xl:w-[0.45rem] bg-main-green" />
      </div>
      <p className="text-white font-normal text-base text-right mb-3 lg:mb-6 2xl:mb-10 lg:text-xl max-w-xl lg:max-w-2xl 2xl:max-w-3xl duration-500 ">
        {t('HOME_PARTNERSHIPS_DESCRIPTION')}
      </p>
      <Link className="z-10" href="/partners" aria-label="redirect to partners page">
        <a className="z-10">
          <LearnMore />
        </a>
      </Link>

      <div className="z-10 mt-16 flex flex-row sm:flex-row w-full items-end sm:items-center justify-center sm:justify-end gap-2 sm:gap-1 md:gap-2 lg:gap-3 2xl:gap-11 child:duration-500">
        {mainPartners.map((partner) => (
          <div key={partner.id}>
            <div className="flex flex-col gap-y-2 items-center w-[4.5rem] h-[4.5rem] md:w-[6.2rem] md:h-[6.2rem] lg:w-[8.5rem] lg:h-[8.5rem] 2xl:w-[9rem] 2xl:h-[9rem]">
              <div className="flex h-full w-full items-center justify-center bg-cover bg-carousel-circle">
                <div className="p-1 h-full w-full hover:scale-105 duration-500">
                  <Image
                    alt={String(partner.id)}
                    height={60}
                    width={60}
                    src={partner.image}
                    layout="responsive"
                  />
                </div>
              </div>
              <span className="text-white text-xs lg:text-md font-normal hover:underline">
                {partner.name}
              </span>
            </div>
          </div>
        ))}
        <div className="hidden sm:flex flex-col text-white ml-4 md:ml-8 lg:ml-7">
          <p className="font-semibold text-2xl select-none">Blockchain</p>
          <p className="font-semibold text-2xl select-none">Partners</p>
        </div>
      </div>
      <div className="z-10 mt-16 flex flex-col sm:flex-row w-full items-end sm:items-center justify-center sm:justify-end child:duration-500">
        <StackAICarousel />
        <div className="hidden sm:flex flex-col text-white">
          <p className="whitespace-nowrap font-semibold text-3xl line leading-7 select-none">
            1700+
          </p>
          <p className="font-light select-none">{t('HOME_PARTNERSHIPS_DEPLOYED')}</p>
        </div>
      </div>
      <div
        style={{ transform: `translateX(-${offsetY * 0.01}px)` }}
        className="selectDisable opacity-60 absolute flex md:hidden w-[40rem] h-[16.87rem] top-[24rem] right-[-12rem] duration-200"
      >
        <Image
          src="/assets/home/governance-background-scribbles-md.svg"
          alt="governance-background-scribbles-md"
          layout="fill"
        />
      </div>
    </div>
  );
};

export default PartnershipsSection;
